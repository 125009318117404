export const BG_COVER = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/CoverNew__iGCo998t.jpg?updatedAt=1704424658612`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/coverdalamnew_qUZKG-_OG.jpg?updatedAt=1704424943247`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/COVER%20CHICO%20STEPHANIE_91tto6yyi.jpg?updatedAt=1703676353596`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/footer_XNVG0G2vc.jpg?updatedAt=1703658199442`;

export const IMG_AYAT = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/footernew_AwuPskN53d.jpg?updatedAt=1704425136451`;
export const IMG_LOGO = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/Monogram%20Hendry%20+%20Clarissa%20(16)_ydNqjBUj5.png?updatedAt=1703673768804`;

export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/the%20groom_TLyzgSdYR.jpg?updatedAt=1703658200412`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/WhatsApp%20Image%202024-01-15%20at%2018.33.10_V7yXYrr8U.jpeg?updatedAt=1705414394782`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/Gal%201_sP_zeZWMVQ.jpg?updatedAt=1703658201205`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/Gal%202_93i4w9oZ4.jpg?updatedAt=1703658201130`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/masansgaming/Cicho%20Stephanie/Gal%203_eCoBx8wtF.jpg?updatedAt=1703658199095`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
